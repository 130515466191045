import React from 'react';
import { FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';

/* Main Menu items starts */
export const MainMenu = [
  { Label: 'Home', link: '/' },
  { Label: 'About us', link: '/about' },
  // { Label: 'Solutions', link: '/solutions'}
];
export const MobileMenu = [
  { Label: 'Home', link: '/', class: 'home' },
  { Label: 'About us', link: '/about', class: 'about' },
  // { Label: 'Solutions', link: '/solutions', class: 'solutions' },
];
/* Main Menu items ends */

/* Main Menu Button starts */
export const MainMenuBtn = {
  Label: 'Contact Us',
  link: '/contact',
};
/* Main Menu Button ends */

/* Home Banner starts */
export const HomeBannerDetails = {
  title: 'Improve royalty distribution accuracy up to 98%',
  description:
    'Our extensible no-code platform helps Rights Management organizations manage Music & Audiovisual metadata.',
  features: [
    'Integrate easily with your existing systems',
    'Import & Export various metadata formats',
    'Match & de-duplicate metadata with AI',
  ],
};
/* Home Banner ends */

/* Home Banner button starts */
export const HomeBannerBtn = {
  Label: 'Schedule a Demo',
  link: '/contact',
};
/* Home Banner button ends */

/* Social share starts */
export const SocialShare = [
  {
    Social: <FaLinkedin size={22} className="linkedin-icon" />,
    link: 'https://www.linkedin.com/company/noctil-platform/',
  },
  {
    Social: <FaTwitter size={22} className="twitter-icon" />,
    link: 'https://twitter.com/noctilcom',
  },
  {
    Social: <FaYoutube size={24} className="youtube-icon" />,
    link: 'https://www.youtube.com/channel/UCxUNMHPO_NFRACGag42H51w',
  },
];
/* Social share ends */

/* Footer Links starts */
export const FooterLinks = [
  { Label: 'About us', link: '/about' },
  { Label: 'Solutions', link: '/solutions' },
  // { Label: 'Resources', link: '/resources' },
  { Label: 'Blogs', link: 'https://blogs.noctil.com/' },
  // { Label: 'Careers', link: '/careers' },
  { Label: "FAQ's", link: '/faqs' },
  { Label: 'Privacy Policy', link: '/privacy-policy' },
];
/* Footer Links ends */

/* Home dashboard carousel settings starts */
export const dashboardCarousel = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  dots: true,
  arrows: true,
  speed: 100,
  autoplaySpeed: 16000,
  fade: true,
  cssEase: 'linear',
  pauseOnHover: false,
};
/* Home dashboard carousel settings ends */

/* Blog Carousel settings starts */
export const blogCarousel = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
/* Blog Carousel settings starts */

export const noctilSolutionsData = {
  'best-metadata-management-tools-software': {
    metadata: {
      // for seo
      imgAlt: 'Metadata Management - Noctil',
      title: 'Best Metadata Management Tools | Metadata Repository Software - Noctil',
      description:
        'Metadata forms the foundation for various information, such as rights management, content discovery, royalty calculation, licensing, and distribution.',
    },
    bannerData: {
      title: 'Metadata Management',
      subTitle: 'Metadata is what gets you revenue.',
      description:
        'Unlock the hidden revenue by enriching the music/audio-visual metadata. para$ Metadata forms the foundation for various information, such as rights management, content discovery, royalty calculation, licensing, and distribution.',
      icon: 'solutions/metadata-management.svg',
    },
    coreFeatures: [
      {
        title: 'Centralized database',
        icon: 'solutions/metadata-management/centralized-database.svg',
        description:
          'Noctil simplifies storing and managing music or audio-visual metadata in a single location, allowing easy access to artist details, work titles, and ownership shares.',
      },
      {
        title: 'Focus on automation',
        icon: 'solutions/metadata-management/focus-on-automation.svg',
        description:
          'Noctil platform offers no-code AI/ML automation to minimize the risk of costly errors in royalty calculations, ultimately increasing revenue opportunities.',
      },
      {
        title: 'Catalog/Reprtoire management',
        icon: 'solutions/metadata-management/catalog-reportoire.svg',
        description:
          'Music catalog management helps companies maximize revenue streams by ensuring accurate rights management, effective licensing, and efficient royalty collection.',
      },
      {
        title: 'Bulk import/export/file storage',
        icon: 'solutions/metadata-management/bulk-export-import.svg',
        description:
          'Noctil system handles large volumes of data in various formats for operations like de-duplication, matching, and royalty calculations. It also imports and exports files in different formats.',
      },
      {
        title: 'Save your searches/advanced filters',
        icon: 'solutions/metadata-management/save-search-and-advanced-filters.svg',
        description:
          'Imagine manually checking the data in the system every day. This becomes a daunting task if you have to keep doing it manually. With the help of an advanced filter application for any metadata field and an option to save the filter, you can perform your task with just one click.',
      },
      {
        title: 'Reporting and Analytics',
        icon: 'solutions/metadata-management/reporting-and-analysis.svg',
        description:
          'Generating reports and analyzing data to gain insights on revenue streams, trends, and music catalog performance.',
      },
    ],
  },
  'music-metadata-identification-and-matching-software': {
    metadata: {
      imgAlt: 'Identification and Matching - Noctil',
      title: 'Music Metadata Identification & Matching Software - Noctil',
      description:
        'The most critical step to perform for any music rights society is metadata identification, de-duplication, and matching to have clean and well-organized metadata.',
    },
    bannerData: {
      title: 'Identification and Matching',
      subTitle: 'Achieve quality metadata through Identification and Matching.',
      description:
        'The most critical step to perform for any music rights society is metadata identification, de-duplication, and matching to have clean and well-organized metadata.',
      icon: 'solutions/identification-and-matching.svg',
    },
    coreFeatures: [
      {
        title: 'Metadata identification',
        icon: 'solutions/identification-and-matching/meta-identification.svg',
        description:
          'It is the process of using metadata attributes to uniquely distinguish a specific piece of data. para$ In the music business, identifying and eliminating duplicates helps you achieve high-quality metadata. This process is crucial since incorrect and duplicate data greatly hinder achieving higher royalty distributions.',
      },
      {
        title: 'Metadata matching',
        icon: 'solutions/identification-and-matching/metadata-matching.svg',
        description:
          "This is mainly a comparison of two different sets of metadata to determine if they represent the same asset.para$ A higher metadata matching percentage will improve the royalty distribution percentage and the process's efficiency.",
      },
      {
        title: 'Improve payment frequency',
        icon: 'solutions/identification-and-matching/improve-payment.svg',
        description:
          'As a result of the above process, the artists will now be offered fair and timely compensation that they are entitled to.',
      },
      {
        title: 'Data sharing is faster',
        icon: 'solutions/identification-and-matching/data-transfer-faster.svg',
        description:
          'Music content distribution to various digital platforms, including streaming services, other rights societies, and radio stations, is now quicker. para$ Also, sharing the rich metadata with third-party sources, such as other rights societies, allows users to earn more.',
      },
    ],
  },
  'member-music-rights-holder-management-solution': {
    metadata: {
      imgAlt: 'Member/Right Holder Management - Noctil',
      title: 'Music Rights Management | RightsHolder Management Software - Noctil',
      description:
        "It is extremely important to ensure that Artist's are also getting what they deserve, i.e., the right support, recognition, and fair pay along with Music organization's growth.",
    },
    bannerData: {
      title: 'Member/Right Holder Management',
      subTitle:
        'With the right claims comes great customer experiences. Noctil helps you achieve this with utmost accuracy.',
      description:
        'Artist relationships with rights societies, publishers, and record labels should be symbiotic. para$ It is extremely important to ensure that along with the company’s growth the artists are also getting what they deserve, i.e., the right support, recognition, and fair pay.',
      icon: 'solutions/member-Rightholder-management.svg',
    },
    coreFeatures: [
      {
        title: 'Faster Identification and registration',
        icon: 'solutions/member-Rightholder-management/faster-identification.svg',
        description:
          'Songwriters, composers, or artists sign up with publishers or record labels, who then register their work with performance rights organizations (PROs) or other rights societies. para$ Noctil offers techniques and tools for faster metadata exchange between different societies. Noctil offers techniques and tools for faster metadata exchange between different societies.',
      },
      {
        title: 'Accurate royalty distribution',
        icon: 'solutions/member-Rightholder-management/accurate-royalty-dist.svg',
        description:
          'Once the royalty amount is collected, the member/rightsholder management systems help calculate and distribute royalties to the appropriate parties. para$ This includes performance royalties, mechanical royalties, sync licensing fees, and more.',
      },
      {
        title: 'Maintaining reports & transparency',
        icon: 'solutions/member-Rightholder-management/maintaining-reports.svg',
        description:
          'Member/rightsholder management systems generate reports detailing the distribution of royalties and the usage of music content. para$ These reports provide transparency to rights holders and ensure they receive fair compensation.',
      },
    ],
  },
  'claim-accurate-and-appropriately-tool': {
    metadata: {
      imgAlt: 'Claim accurately & appropriately - Noctil',
      title: 'Best Claim Accurate Tool & Software - Noctil',
      description:
        "This can lead to a loss of revenue, stagnate artists' careers, and upturn the music industry as a whole. Noctil helps you achieve this with 100% accuracy.",
    },
    bannerData: {
      title: 'Claim accurately & appropriately',
      subTitle: 'Noctil helps you achieve this with 100% accuracy.',
      description:
        "Imagine claims of metadata not being 100% correct. para$ This can lead to a loss of revenue, stagnate artists' careers, and upturn the music industry as a whole. para$ However, there is a way that metadata can be shared in the right format with other organizations.",
      icon: 'solutions/claim-accurately-and-appropriately.svg',
    },
    coreFeatures: [
      {
        title: 'Support royalty claims',
        icon: 'solutions/claim-accurately-and-appropriately/support-royalty-claim.svg',
        description:
          'You can now claim all the royalty and revenue from streaming services, digital downloads, radio broadcasts, live performances, sync licensing, and more. para$ This is because of the accurate and quality metadata produced by the processes performed through the Noctil platform. Accurate claiming ensures that artists and rights holders receive their fair share of income.',
      },
      {
        title: 'Export metadata',
        icon: 'solutions/claim-accurately-and-appropriately/export-metadata.svg',
        description:
          'Easily export bulk metadata using a variety of formats that can be exchanged with organizations worldwide. para$ You can also apply the advanced filters to reduce applying all the conditions while exporting and downloading the files.',
      },
      {
        title: 'Copyright claims',
        icon: 'solutions/claim-accurately-and-appropriately/copyright-claims.svg',
        description:
          'Metadata that is complete can help you claim licensing fees accurately. para$ This involves licensing music content for various purposes, such as film, television, commercials, and video games and allowing the music users to consume the work in compliance with licensing terms and that the appropriate permissions are granted.',
      },
    ],
  },
  'music-metadata-transformation-tool': {
    metadata: {
      imgAlt: 'Data Transformation - Noctil',
      title: 'Music Metadata Transformation Tool | CWR & DDEX Transformation- Noctil',
      description:
        'Due to a huge gap in how the metadata is being populated in each organization’s systems, and during the exchange of data, having no proper standard or format can lead to data loss.',
    },
    bannerData: {
      title: 'Data Transformation',
      subTitle:
        'We can help you transform raw music - metadata into a structured, standardized, and enriched format.',
      description:
        'Industry focus right now is on creating a standardized data format for exchange with various organizations. para$ There is a huge gap in how the metadata is being populated in each organization’s systems, and during the exchange of data, having no proper standard or format can lead to data loss.',
      icon: 'solutions/data-transformation.svg',
    },
    coreFeatures: [
      {
        title: 'Ingest data easily into your system',
        icon: 'solutions/data-transformation/ingest-data.svg',
        description:
          'High volumes of raw music-related data are collected from various sources, including music labels, publishers, streaming platforms, and other data providers. para$ This data may come in different formats, such as databases or unstructured text.',
      },
      {
        title: 'Data Standardization',
        icon: 'solutions/data-transformation/data-standardization.svg',
        description:
          'Once the metadata is collected and stored, it needs to be standardized to match industry-specific metadata standards. para$ Naming conventions, data formats, and industry codes (e.g., ISRC, ISWC) matter to maintain data integrity. While exporting bulk data, users can convert into the required standard format, such as CWR, JSON, or any other formats.',
      },
      {
        title: 'De-duplication and Matching',
        icon: 'solutions/data-transformation/de-duplication-and-matching.svg',
        description:
          "Identifying and eliminating duplicates helps you achieve high-quality metadata. This, in turn, helps increase the revenue percentage. para$A higher metadata matching percentage will improve the royalty distribution percentage and the process's efficiency.",
      },
    ],
  },
  'distribution-and-payment-calculation': {
    metadata: {
      imgAlt: 'Distribution & Payment calculation - Noctil',
      title: 'Best Distribution & Payment calculation Software - Noctil',
      description:
        'Have you spent hours generating rules and running through Excel spreadsheets for calculating royalty and revenue? Ever thought of having an automated system to help you?',
    },
    bannerData: {
      title: 'Distribution & Payment calculation',
      subTitle:
        'Noctil breaks down multiple rules & automates the royalty calculations with just one click.',
      description:
        'Have you spent hours generating rules and running through Excel spreadsheets for calculating royalty and revenue? Ever thought of having an automated system to help you with precise royalty calculations and payment distribution to the various parties, including rights holders, artists, songwriters, publishers, and other stakeholders?',
      icon: 'solutions/distribution-and-payment-calculation.svg',
    },
    coreFeatures: [
      {
        title: 'Faster royalty calculation',
        icon: 'solutions/distribution-and-payment-calculation/faster-royalty-calculation.svg',
        description:
          'The software calculates the royalties owed to rights holders for various types of music usage. para$ These calculations are automated based on rules and the share-holding percentages among different parties.',
      },
      {
        title: 'Royalty rate application',
        icon: 'solutions/distribution-and-payment-calculation/royalty-rate.svg',
        description:
          'Various types of music usage may have different royalty rates and payment structures. para$ The software applies the appropriate rates and rules to each usage scenario.',
      },
      {
        title: 'Data validation',
        icon: 'solutions/distribution-and-payment-calculation/data-validation.svg',
        description:
          'To ensure the accuracy of calculations, the system validates the incoming metadata and checks for discrepancies or errors that may affect royalty calculations.',
      },
    ],
  },
  'custom-portal': {
    metadata: {
      imgAlt: 'Custom Portal - Noctil',
      title: 'Best Custom Portal Software Solutions - Noctil',
      description:
        'We offer a highly personalized and branded experience for your team and right holders, allowing them to interact with the software in a way that aligns with their goals and enhances their experience.',
    },
    bannerData: {
      title: 'Custom Portal',
      subTitle:
        'Get a custom portal based on your company’s requirements with your brand label.',
      description:
        'We offer a highly personalized and branded experience for your team and right holders, allowing them to interact with the software in a way that aligns with their goals and enhances their experience.',
      icon: 'solutions/custom-portal.svg',
    },
    coreFeatures: [
      {
        title: 'Portal user access control',
        icon: 'solutions/custom-portal/portal-user-access.svg',
        description:
          'The custom portal allows admin users to define access controls and permissions, ensuring that only authorized users or a group of users with certain roles within the organization can view and modify metadata, access reports, or perform other actions.',
      },
      {
        title: 'Data import and export',
        icon: 'solutions/custom-portal/data-import-export.svg',
        description:
          'Users can easily import and export data from the portal. para$ This includes defining data formats, applying advanced filters, and saving searches.',
      },
      {
        title: 'Application customization',
        icon: 'solutions/custom-portal/app-customization.svg',
        description:
          'Clients can request or create specific functionalities and tools essential to their metadata management needs. para$ These features are developed and integrated into the portal to streamline the operations.',
      },
    ],
  },
};

/* Solutions items starts */
export const SolutionsItems = [
  {
    title: 'Metadata Management',
    icon: 'solutions/metadata-management.svg',
    description:
      noctilSolutionsData['best-metadata-management-tools-software'].metadata.description,
    url: '/solutions/best-metadata-management-tools-software',
  },
  {
    title: 'Member/Right Holder Management',
    icon: 'solutions/member-Rightholder-management.svg',
    description:
      noctilSolutionsData['member-music-rights-holder-management-solution'].metadata
        .description,
    url: '/solutions/member-music-rights-holder-management-solution',
  },
  {
    title: 'Identification and Matching',
    icon: 'solutions/identification-and-matching.svg',
    description:
      noctilSolutionsData['music-metadata-identification-and-matching-software'].metadata
        .description,
    url: '/solutions/music-metadata-identification-and-matching-software',
  },
  {
    title: 'Data Transformation',
    icon: 'solutions/data-transformation.svg',
    description:
      noctilSolutionsData['music-metadata-transformation-tool'].metadata.description,
    url: '/solutions/music-metadata-transformation-tool',
  },
  {
    title: 'Distribution and Payment calculation',
    icon: 'solutions/distribution-and-payment-calculation.svg',
    description:
      noctilSolutionsData['distribution-and-payment-calculation'].metadata.description,
    url: '/solutions/distribution-and-payment-calculation',
  },
  {
    title: 'Custom Portal',
    icon: 'solutions/custom-portal.svg',
    description: noctilSolutionsData['custom-portal'].metadata.description,
    url: '/solutions/custom-portal',
  },
];
/* Solutions items ends */

/* Career items starts */
export const CareersData = [
  {
    title: 'Senior Software Engineer',
    icon: 'careers/senior-software-engineer.svg',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient.',
  },
  {
    title: 'Marketing Manager',
    icon: 'careers/marketing-manager.svg',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient.',
  },
  {
    title: 'Product Manager',
    icon: 'careers/product-manager.svg',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient.',
  },
  {
    title: 'Brand Manager',
    icon: 'careers/brand-manager.svg',
    description:
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient.',
  },
];
/* Career items ends */

/* Blog Carousel items starts */
export const blogList = [
  {
    image: '/assets/images/blogs/1/aipa-story-investing-in-the-future-1.svg',
    title: 'AIPA Success Story: Investing In The Future',
    publisedDate: 'September 20, 2019',
    url: '/aipa-success-story',
  },
  {
    image:
      '/assets/images/blogs/2/reinventing-the-global-repertoire-db-for-the-music-industry-1.svg',
    title: 'Reinventing The Global Repertoire Database For The Music Industry',
    publisedDate: 'September 12, 2018',
    url: '/music-repertoire-databse',
  },
  {
    image:
      '/assets/images/blogs/3/does-blockchain-revolutionize-the-music-industry-1.svg',
    title: 'Does Blockchain Revolutionize The Music Industry?',
    publisedDate: 'September 04, 2018',
    url: '/blockchain-in-music',
  },
  {
    image: '/assets/images/blogs/4/rent-buy-build-1.svg',
    title: 'Rent, Buy Or Build?',
    publisedDate: 'September 04, 2018',
    url: '/rent-buy-build',
  },
];
/* Blog Carousel items ends */

export const headerSolutionsItems = [
  {
    title: 'Metadata Management',
    url: '/solutions/best-metadata-management-tools-software',
    icon: 'solutions/metadata-management.svg', // 1
  },
  {
    title: 'Data Transformation',
    url: '/solutions/music-metadata-transformation-tool',
    icon: 'solutions/data-transformation.svg',
  }, // 5
  {
    title: 'Identification and Matching',
    url: '/solutions/music-metadata-identification-and-matching-software',
    icon: 'solutions/identification-and-matching.svg',
  },
  {
    title: 'Distribution and Payment calculation',
    url: '/solutions/distribution-and-payment-calculation',
    icon: 'solutions/distribution-and-payment-calculation.svg',
  },

  {
    title: 'Member/Right Holder Management',
    url: '/solutions/member-music-rights-holder-management-solution',
    icon: 'solutions/member-Rightholder-management.svg',
  },
  {
    title: 'Custom Portal',
    url: '/solutions/custom-portal',
    icon: 'solutions/custom-portal.svg',
  },
];
